import React from 'react'
import styled from 'react-emotion'

const Container = styled.div`
  background-color: green;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  display: flex;

  .container {
    grid-template-columns: 40px 50px auto 40px;
    grid-template-rows: 10% auto 5%;
  }

`
const Menu = styled.div`
  background-color: red;
  width: 25%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const PicAMe = styled.div`
  background-color: blue;
  height: 600px;
  overflow: hidden;
`

const SomeTextAScribinMe = styled.div`
  background-color: green;
  color: white;
  margin: 10% 5%;
`

const SomeLinks = styled.div`
  background-color: aliceblue;
  width: 10%;
`

const Content = styled.div`
  background-color: purple;
  width: 90%;
`

export default class TwoColumnAbout extends React.Component {
  render() {
   return (
     <Container>
       <Menu>
         <PicAMe>
           <img src="https://loremflickr.com/600/600" />
         </PicAMe>
         <SomeTextAScribinMe>
           <h2>
           HTMLDRUM is the blog and fountainhead of a pudgey server side programmer from Sydney.
           James' interests are in emerging tooling and techniques for running digital infrastructure at scale.
          </h2>
         </SomeTextAScribinMe>
         <SomeLinks />
       </Menu>
       <Content />
     </Container>
   );
  }
};